import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/paths';

export const UserPage = observer(() => {
	const { t } = useTranslation();
	const { UserStore } = useStores();
	const { user } = UserStore;
	const location = useLocation();
	const navigate = useNavigate();

	const birthDateConcat = useCallback((user) => {
		if (user?.birthDay && user?.birthMonth && user?.birthYear) {
			const day = user.birthDay < 10 ? `0${user.birthDay}` : user.birthDay;
			const month = user.birthMonth < 10 ? `0${user.birthMonth}` : user.birthMonth;
			return `${day}.${month}.${user.birthYear}`;
		}
		return '';
	}, []);

	const handleBackClick = () => {
		navigate(location.key === 'default' ? PATHS.home : -1);
	};

	return (
		<div className="page user-page">
			<Grid container columnSpacing={6} rowSpacing={6}>
				<Grid item sm={12} xs={12}>
					<Typography variant="h2" className="mt-32">
						{t('user.content.title')}
					</Typography>
					<Typography className="mt-16">
						<b>{t('user.content.subtitle')}</b>
					</Typography>
				</Grid>
				<Grid item sm={12} xs={12}>
					<Typography variant="h3" className="mb-24">
						{t('user.form.title')}
					</Typography>
					<div className="user-field">
						<Typography>{t('user.form.name')}</Typography>
						<input id="name" type="text" value={user?.name} readOnly={true} />
					</div>
					<div className="user-field">
						<Typography>{t('user.form.surname')}</Typography>
						<input id="surname" type="text" value={user?.surname} readOnly={true} />
					</div>
					<div className="user-field">
						<Typography>{t('user.form.birthdate')}</Typography>
						<input id="birthdate" type="text" value={birthDateConcat(user)} readOnly={true} />
					</div>
					<div className="user-field">
						<Typography>{t('user.form.email')}</Typography>
						<input id="email" type="text" value={user?.email} readOnly={true} />
					</div>
				</Grid>
				<Grid item sm={12} xs={12}>
					<Button id="back-link" variant="contained" onClick={handleBackClick}>
						{t('back')}
					</Button>
				</Grid>
			</Grid>
		</div>
	);
});
