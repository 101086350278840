import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Stack, Pagination, List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import { useStores } from './store-provider';
import { useLoading } from '../utils/hooks';
import { GET_GROUPS } from '../services/api-calls';
import { LoaderLocal } from './loader-local';

export const GroupsListSelector = observer(({ value, onChange, disabled }) => {
	const { GroupsStore } = useStores();
	const { groups, pagesCount, currentPage, setCurrentPage } = GroupsStore;

	const [getGroups, loading] = useLoading(GET_GROUPS);
	const checkedIds = useMemo(() => value ?? [], [value]);

	const handlePageChange = useCallback(
		(event, page) => {
			setCurrentPage(page);
			getGroups(page);
		},
		[getGroups, setCurrentPage],
	);

	useEffect(() => {
		getGroups(1);
	}, [getGroups]);

	const setGroupHandler = useCallback(
		(groupId) => {
			if (groupId === null) {
				onChange([]); // All groups
				return;
			}
			if (checkedIds.includes(groupId)) {
				onChange(checkedIds.filter((id) => id !== groupId));
				return;
			}

			onChange([...checkedIds, groupId]);
		},
		[checkedIds, onChange],
	);

	return (
		<Stack className="groups-list-selector">
			<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
				<ListItem key="all" disablePadding sx={{ mb: 1 }}>
					<ListItemButton onClick={() => setGroupHandler(null)} dense className="group-item" disabled={disabled}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={!checkedIds.length}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': 1 }}
							/>
						</ListItemIcon>
						<ListItemText id={1} primary="All" />
					</ListItemButton>
				</ListItem>
				{groups?.map((group) => (
					<ListItem key={group.id} disablePadding sx={{ mb: 1 }}>
						<ListItemButton onClick={() => setGroupHandler(group.id)} dense className="group-item" disabled={disabled}>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={checkedIds.includes(group.id)}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': 1 }}
								/>
							</ListItemIcon>
							<ListItemText id={1} primary={group.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			{pagesCount > 1 && <Pagination count={pagesCount} page={currentPage} color="primary" onChange={handlePageChange} />}
			{loading && <LoaderLocal />}
		</Stack>
	);
});
