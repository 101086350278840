import { Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SendMessageForm } from 'common-frontend/components/forms/send-message-form';
import { MessagesHistory } from 'common-frontend/components/messages-history';
import { BroadcastNav } from '../components/broadcast-nav';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';

export const MessagesPage = observer(() => {
	const { t } = useTranslation();

	const { MessagesStore } = useStores();
	const { selectedMessage } = MessagesStore;

	return (
		<div className="page messages-page">
			<BroadcastNav />
			<Stack spacing={6}>
				<div>
					<Typography variant="h2" className="mt-32">
						{t('broadcast.messages.content.title')}
					</Typography>
					<Typography className="mt-16">
						<b>{t('broadcast.messages.content.subtitle')}</b>
					</Typography>
				</div>
				<Stack direction="row" spacing={3}>
					<Box width="30%">
						<MessagesHistory />
					</Box>
					<Box width="70%">
						<SendMessageForm message={selectedMessage} enableGroupsSelection />
					</Box>
				</Stack>
			</Stack>
		</div>
	);
});
