import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { PATHS } from '../../constants/paths';
import React from 'react';

export const BroadcastNav = () => {
	const { t } = useTranslation();
	return (
		<Box className="broadcast-nav">
			<NavLink to={PATHS.messages}>{t('broadcast.messages.nav')}</NavLink>
			<NavLink to={PATHS.groups}>{t('broadcast.groups.nav')}</NavLink>
		</Box>
	);
};
