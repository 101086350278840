import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BroadcastNav } from '../components/broadcast-nav';
import { GroupsList } from 'common-frontend/components/groups-list';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { GroupForm } from 'common-frontend/components/forms/group-form';

export const GroupsPage = observer(() => {
	const { t } = useTranslation();
	const [showForm, setShowForm] = useState(false);
	const { GroupsStore } = useStores();
	const { selectedGroup, setSelectedGroup } = GroupsStore;

	useEffect(() => {
		if (selectedGroup && !showForm) {
			setShowForm(true);
		}
	}, [selectedGroup, showForm]);

	const handleNewClick = useCallback(() => {
		if (!showForm) {
			setShowForm(true);
		}

		if (selectedGroup) {
			setSelectedGroup(null);
		}
	}, [selectedGroup, setSelectedGroup, showForm]);

	return (
		<div className="page groups-page">
			<BroadcastNav />
			<Stack spacing={6}>
				<div>
					<Typography variant="h2" className="mt-32">
						{t('broadcast.groups.content.title')}
					</Typography>
					<Typography className="mt-16">
						<b>{t('broadcast.groups.content.subtitle')}</b>
					</Typography>
				</div>
				<Stack direction="row" spacing={3}>
					<Box width="30%">
						<GroupsList disableNew={showForm && !selectedGroup} onNewClick={handleNewClick} />
					</Box>
					<Box width="70%">{showForm && <GroupForm group={selectedGroup} />}</Box>
				</Stack>
			</Stack>
		</div>
	);
});
