import { Stack, Typography, Button, IconButton, Box, Card, CardContent, CardActions, Divider, Chip, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useLoading, useToggle } from 'common-frontend/utils/hooks';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import { dateFormatter } from 'common-frontend/utils/formatters';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from './store-provider';
import { GET_HISTORY } from '../services/api-calls';
import { LoaderLocal } from './loader-local';
import AddIcon from '@mui/icons-material/Add';

export const MessagesHistory = observer(() => {
	const { t } = useTranslation();
	const { MessagesStore } = useStores();
	const { messages, setSelectedMessageId, selectedMessageId } = MessagesStore;
	const [getHistory, loading] = useLoading(GET_HISTORY);

	useEffect(() => {
		getHistory();
	}, [getHistory]);

	return (
		<Box className="messages-history">
			<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Typography variant="h4">{t('broadcast.messages.messages-history')}</Typography>
				<Stack direction="row" flexWrap="wrap" alignItems="center">
					<IconButton size="small" onClick={getHistory} disabled={loading}>
						<RefreshIcon color="primary" />
					</IconButton>
					{/* <IconButton size="small">
						<CloseIcon />
					</IconButton> */}
				</Stack>
			</Stack>
			<Stack mt={2}>
				<Button
					variant="outlined"
					color="primary"
					size="small"
					startIcon={<AddIcon />}
					disabled={!selectedMessageId}
					onClick={() => {
						setSelectedMessageId(null);
					}}
				>
					{t('new')}
				</Button>
			</Stack>
			<HistoryList
				messages={messages}
				loading={loading}
				t={t}
				setSelectedMessageId={setSelectedMessageId}
				selectedMessageId={selectedMessageId}
			/>
			{loading && <LoaderLocal />}
		</Box>
	);
});

const HistoryList = ({ messages, loading, t, setSelectedMessageId, selectedMessageId }) => {
	if (!messages || messages.length === 0) {
		return loading ? null : <Typography mt={3}>{t('broadcast.messages.no-messages-found')}</Typography>;
	}

	return (
		<Stack className="history-items-list" spacing={3} mt={3}>
			{messages.map((item) => (
				<HistoryItem
					key={item.id}
					item={item}
					t={t}
					setSelectedMessageId={setSelectedMessageId}
					selected={item.id === selectedMessageId}
				/>
			))}
		</Stack>
	);
};

const HistoryItem = ({ item, t, selected, setSelectedMessageId }) => {
	const [showMessage, toggleMessage] = useToggle(false);
	const toggleHandler = (event) => {
		event.stopPropagation();
		toggleMessage();
	};

	return (
		<Card elevation={3} className={`history-item ${selected ? 'selected' : ''}`} onClick={() => setSelectedMessageId(item.id)}>
			<CardContent>
				<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start">
					<span className="history-item__subject">{item.subject}</span>
					<MessagesChip delivered={item.deliveredMessages} expected={item.expectedMessages} t={t} />
				</Stack>
				<span className="history-item__date">{dateFormatter(item.sentAt)}</span>
				{showMessage && (
					<>
						<Divider sx={{ my: 2 }} />
						<span className="history-item__body-text">{item.bodyText}</span>
					</>
				)}
			</CardContent>
			<CardActions>
				<Button size="small" onClick={toggleHandler}>
					{showMessage ? t('broadcast.messages.hide-message') : t('broadcast.messages.show-message')}
				</Button>
			</CardActions>
		</Card>
	);
};

const MessagesChip = ({ delivered, expected, t }) => (
	<Tooltip title={<span style={{ fontSize: '14px' }}>{t('broadcast.messages.numbers-chip')}</span>}>
		<Chip
			className="history-item__chip"
			label={`${delivered} / ${expected}`}
			size="small"
			sx={{ fontSize: '14px', cursor: 'default' }}
		/>
	</Tooltip>
);
