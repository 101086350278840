import React, { useCallback, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { SEND_HOMEWORK, SEND_ID_CARD, GET_DOCUMENTS_LIST } from '../../services/api-calls';
import { DOWNLOAD_DOCUMENT } from 'common-frontend/services/api-calls';
import { useStores } from 'common-frontend/components/store-provider';
import { OutboundDocumentsTable } from '../components/tables';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FileUploader } from 'common-frontend/components/file-uploader';

const PAGE_SIZE = 10;

export const ActivitiesPage = observer(() => {
	const { t } = useTranslation();
	const { DocumentsStore, GuidesStore, UserStore } = useStores();
	const { user } = UserStore;
	const { setAllRightMessageType } = GuidesStore;
	const { documents, pagesCount, currentPage, setCurrentPage } = DocumentsStore;

	const [idLoader, setIdLoader] = React.useState(false);
	const [homeworkLoader, setHomeworkLoader] = React.useState(false);
	const [docsLoader, setDocsLoader] = React.useState(false);

	const getIdCardHandler = useCallback(() => {
		setIdLoader(true);
		SEND_ID_CARD().finally(() => {
			setAllRightMessageType('id-card'); // triggers Guides >> stepRunners[8]
			setIdLoader(false);
		});
	}, [setAllRightMessageType]);

	const getHomeworkHandler = useCallback(() => {
		setHomeworkLoader(true);
		SEND_HOMEWORK().finally(() => {
			setAllRightMessageType('homework'); // triggers Guides >> stepRunners[9]
			setHomeworkLoader(false);
		});
	}, [setAllRightMessageType]);

	const getDocumentsFinally = () => {
		setDocsLoader(false);
	};

	const getDocumentsHandler = useCallback(() => {
		setDocsLoader(true);
		setCurrentPage(1);
		GET_DOCUMENTS_LIST(1, PAGE_SIZE, 'outbound').finally(getDocumentsFinally);
	}, [setCurrentPage]);

	const downloadDocument = useCallback((doc) => {
		DOWNLOAD_DOCUMENT(doc);
	}, []);

	const handlePageChange = useCallback(
		(e, newPage) => {
			setDocsLoader(true);
			setCurrentPage(newPage);
			GET_DOCUMENTS_LIST(newPage, PAGE_SIZE, 'outbound').finally(getDocumentsFinally);
		},
		[setCurrentPage],
	);

	useEffect(() => {
		getDocumentsHandler();
	}, [getDocumentsHandler]);

	return (
		<div className="page activities-page">
			<Grid container columnSpacing={6} rowSpacing={6}>
				<Grid item sm={12} xs={12}>
					<Typography variant="h2" className="mt-32">
						{t('activities.content.title')}
					</Typography>
					<Typography className="mt-16">
						<b>{t('activities.content.subtitle')}</b>
					</Typography>
				</Grid>
				<Grid item sm={6} xs={12}>
					<aside className="h-100">
						<h3 className="mb-8">{t('activities.content.title-send-id')}</h3>
						<Typography className="mb-24">{t('activities.content.description-send-id')}</Typography>
						<Button
							id="send-id-button"
							variant="contained"
							color="secondary"
							className="mt-auto"
							disabled={idLoader || !user}
							onClick={getIdCardHandler}
						>
							{t('activities.content.button-send-id')}
						</Button>
					</aside>
				</Grid>
				<Grid item sm={6} xs={12}>
					<aside className="h-100">
						<h3 className="mb-8">{t('activities.content.title-send-homework')}</h3>
						<Typography className="mb-24">{t('activities.content.description-send-homework')}</Typography>
						<Button
							id="send-homework-button"
							variant="contained"
							color="secondary"
							className="mt-auto"
							disabled={homeworkLoader || !user}
							onClick={getHomeworkHandler}
						>
							{t('activities.content.button-send-homework')}
						</Button>
					</aside>
				</Grid>
				<Grid item sm={6} xs={12}>
					<Button
						id="refresh-documents-button"
						className="ml-16"
						variant="contained"
						disabled={docsLoader || !user}
						onClick={getDocumentsHandler}
					>
						<RefreshIcon />
					</Button>
					<FileUploader buttonClassName="ml-16" handleUpload={getDocumentsHandler} />
				</Grid>
				<Grid item sm={12} xs={12}>
					<Typography className="mb-16">{t('activities.content.outbound-help-text')}</Typography>
					<OutboundDocumentsTable
						documents={documents}
						loading={docsLoader || !user}
						pagesCount={pagesCount}
						currentPage={currentPage}
						downloadDocument={downloadDocument}
						handlePageChange={handlePageChange}
						t={t}
					/>
				</Grid>
			</Grid>
		</div>
	);
});
