import { Pagination, Stack, Typography, Button, IconButton, Box, Card, CardContent } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useLoading } from 'common-frontend/utils/hooks';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from './store-provider';
import { DELETE_GROUP, GET_GROUPS } from '../services/api-calls';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoaderLocal } from './loader-local';
import { AcceptPopup } from './accept-popup';
import { useToggle } from '../utils/hooks';

export const GroupsList = observer(({ disableNew, onNewClick }) => {
	const { t } = useTranslation();
	const { GroupsStore } = useStores();
	const { groups, setSelectedGroup, selectedGroup, pagesCount, currentPage, setCurrentPage } = GroupsStore;

	const [getGroups, loading] = useLoading(GET_GROUPS);

	const handlePageChange = useCallback(
		(event, page) => {
			setCurrentPage(page);
			getGroups(page);
		},
		[getGroups, setCurrentPage],
	);

	useEffect(() => {
		getGroups(1);
	}, [getGroups]);

	return (
		<Box className="groups-list">
			<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Typography variant="h4">{t('broadcast.groups.groups')}</Typography>
				<Stack direction="row" flexWrap="wrap" alignItems="center">
					<IconButton size="small" onClick={() => getGroups(currentPage)} disabled={loading}>
						<RefreshIcon color="primary" />
					</IconButton>
				</Stack>
			</Stack>
			<Stack mt={2}>
				<Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} disabled={disableNew} onClick={onNewClick}>
					{t('new')}
				</Button>
			</Stack>
			<Stack spacing={3} mt={3}>
				{groups?.map((item) => (
					<GroupItem
						key={item.id}
						item={item}
						t={t}
						setSelectedGroup={setSelectedGroup}
						selected={item.id === selectedGroup?.id}
					/>
				))}
			</Stack>
			{pagesCount > 1 && <Pagination count={pagesCount} page={currentPage} color="primary" onChange={handlePageChange} />}
			{loading && <LoaderLocal />}
		</Box>
	);
});

const GroupItem = React.memo(function GroupItem({ item, setSelectedGroup, selected, t }) {
	const [deleteGroup, loading] = useLoading(DELETE_GROUP);
	const [deletePopup, toggleDeletePopup] = useToggle(false);

	const handleDeleteClick = useCallback(
		(event) => {
			event.stopPropagation();
			toggleDeletePopup();
		},
		[toggleDeletePopup],
	);

	const deleteHandler = useCallback(() => {
		deleteGroup(item.id).then(() => {
			toggleDeletePopup();
		});
	}, [deleteGroup, item.id, toggleDeletePopup]);

	return (
		<>
			<Card elevation={3} className={`group-item ${selected ? 'selected' : ''}`}>
				{loading && <LoaderLocal />}
				<CardContent onClick={() => setSelectedGroup(item)}>
					<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
						<Typography className="history-item__subject">{item.name}</Typography>
						<IconButton aria-label="delete" onClick={handleDeleteClick} data-testid="delete-group-button" disabled={loading}>
							<DeleteOutlineIcon sx={{ fontSize: 20 }} />
						</IconButton>
					</Stack>
				</CardContent>
			</Card>
			<AcceptPopup
				id="delete-group-popup"
				title={t('broadcast.groups.delete-popup.title', { group: item.name })}
				text={t('broadcast.groups.delete-popup.text', { group: item.name })}
				isOpen={deletePopup}
				isDanger
				onClose={toggleDeletePopup}
				action={deleteHandler}
			/>
		</>
	);
});
