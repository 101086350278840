import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { IMAGES } from '../../constants/images';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Trans, useTranslation } from 'react-i18next';
import { URLS } from 'common-frontend/constants/env';

export const HomePage = () => {
	const { t } = useTranslation();

	return (
		<div className="page home-page">
			<Grid container spacing={6}>
				<Grid item sm={12} xs={12}>
					<Typography variant="h2" className="mt-32">
						{t('home.content.title')}
					</Typography>
					<Typography className="mb-16 mt-16">
						<b>{t('home.content.subtitle')}</b>
					</Typography>
					<Typography>
						<Trans i18nKey="home.content.text" />
					</Typography>
				</Grid>
				<Grid item sm={4} xs={12}>
					<img src={IMAGES.school} style={{ width: '100%' }} alt="school" />
					<aside className="mb-16 mt-16">
						<h3>{t('tooltips.uni-link-title')}</h3>
						<Button
							id="to-university-link"
							className="mt-16 disabled"
							variant="contained"
							color="secondary"
							href={URLS.university}
							target="_blank"
						>
							<KeyboardDoubleArrowRightIcon />
							{t('tooltips.uni-link-click-here')}
						</Button>
					</aside>
				</Grid>
				<Grid item sm={8} xs={12}>
					<Typography variant="h3">{t('home.content.article-1.title')}</Typography>
					<Typography className="mb-16 mt-16">
						<Trans i18nKey="home.content.article-1.text" />
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};
