import React from 'react';
import { DataGrid as DataGridMUI } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { deDE, enUS } from '@mui/x-data-grid/locales';

const locales = { de: deDE, en: enUS };

export const DataGrid = (props) => {
	const { i18n } = useTranslation();

	return <DataGridMUI {...props} localeText={locales[i18n.language].components.MuiDataGrid.defaultProps.localeText} />;
};
