export function debouncePromise(fn, delay = 500) {
	let timer;

	return (...args) => {
		if (timer) clearTimeout(timer);

		return new Promise((resolve, reject) => {
			timer = setTimeout(async () => {
				try {
					const result = await fn(...args);

					resolve(result);
				} catch (error) {
					reject(error);
				}
			}, delay);
		});
	};
}
