import React from 'react';
import { Stack, CircularProgress } from '@mui/material';

export const LoaderLocal = ({ size = 24 }) => {
	return (
		<Stack className="loader-local" alignItems="center" justifyContent="center">
			<CircularProgress size={size} />
		</Stack>
	);
};
