import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { SearchBar } from './search-bar';
import { Box, Stack } from '@mui/material';
import { useLoading } from '../../utils/hooks';
import { SEARCH_USERS } from '../../services/api-calls';
import { debouncePromise } from '../../utils/timing';
import { arrayDiff } from '../../utils/array';
import { DataGrid } from './data-grid';
import { useTranslation } from 'react-i18next';

const SEARCH_USERS_DEBOUNCED = debouncePromise(SEARCH_USERS);

export const UsersSearcher = ({ selectedUsers: selectedUsersParam, onChange }) => {
	const selectedUsers = useMemo(() => selectedUsersParam ?? [], [selectedUsersParam]);
	const selectedIds = useMemo(() => selectedUsers.map((u) => u.id), [selectedUsers]);
	const [rows, setRows] = useState([]);
	const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
	const [sortModel, setSortModel] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [totalRows, setTotalRows] = useState(0);
	const [searchUsers, loading] = useLoading(SEARCH_USERS_DEBOUNCED);
	const { t } = useTranslation();

	const startSearch = useCallback(() => {
		searchUsers(searchValue, paginationModel, sortModel, setRows, setTotalRows);
	}, [paginationModel, searchUsers, searchValue, sortModel]);

	useEffect(() => {
		if (searchValue) {
			startSearch();
		}
	}, [searchValue, startSearch]);

	const handleSearch = useCallback((s) => {
		setSearchValue(s);
		setPaginationModel((prev) => ({ ...prev, page: 0 }));
	}, []);

	const handleRowSelect = useCallback(
		(newSelectedIds) => {
			const isRowAdded = newSelectedIds.length > selectedIds.length;
			let newSelectedUsers;

			if (isRowAdded) {
				const addedIds = arrayDiff(newSelectedIds, selectedIds);
				const addedUsers = rows.filter((row) => addedIds.includes(row.id));

				newSelectedUsers = [...selectedUsers, ...addedUsers];
			} else {
				const removedIds = arrayDiff(selectedIds, newSelectedIds);

				newSelectedUsers = selectedUsers.filter((user) => !removedIds.includes(user.id));
			}

			onChange(newSelectedUsers);
		},
		[selectedIds, onChange, selectedUsers, rows],
	);

	const columns = [
		{ field: 'name', headerName: t('tables.user.name'), flex: 1 },
		{ field: 'surname', headerName: t('tables.user.surname'), flex: 1 },
		{
			field: 'groups',
			headerName: t('tables.user.groups'),
			valueGetter: (value) => value?.map((group) => group.name).join(', '),
			flex: 2,
			sortable: false,
		},
	];

	return (
		<div>
			<Stack spacing={2} maxWidth={750}>
				<Box width="50%">
					<SearchBar
						placeHolder={t('broadcast.groups.users-search-bar-placeholder')}
						loading={rows?.length > 0 ? undefined : loading}
						onSearchChange={handleSearch}
						onSearchPress={startSearch}
					/>
				</Box>
				{searchValue && (
					<DataGrid
						rows={rows}
						columns={columns}
						rowCount={totalRows}
						paginationMode="server"
						pageSizeOptions={[5, 10]}
						checkboxSelection
						loading={loading}
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						disableColumnMenu
						sortingMode="server"
						onSortModelChange={setSortModel}
						rowSelectionModel={selectedIds}
						onRowSelectionModelChange={handleRowSelect}
						keepNonExistentRowsSelected
						// Selected rows count includes default selected users that may be not in the table, so hiding it
						hideFooterSelectedRowCount
					/>
				)}
			</Stack>
		</div>
	);
};
